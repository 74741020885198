<template>
  <aside class="custom-sidebar">
    <div>
      <a href="/main" class="brand-link">
        <img src="/img/logo.png" :alt="$store.state.app.name" class="brand-image img-circle" style="opacity: 0.8" />
      </a>
      <div class="sidebar">
        <hr>
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <nav-item-component class="ml-1" icon="fas fa-home text-light" link="/main"></nav-item-component>
            <nav-item-component class="ml-1" icon="fas fa-upload text-light"
              link="/main/filemanager"></nav-item-component>
          </ul>
        </nav>
      </div>
    </div>
    <div class="image">
      <img src="/adminlte/dist/img/user2-160x160.jpg" class="brand-image img-circle" alt="User Image" />
    </div>
  </aside>
</template>

<script>
import NavItemComponent from './NavItemComponent.vue'

export default {
  components: {
    NavItemComponent,
  },
  mounted() {
    document.body.classList.add('sidebar-collapse');
    document.body.classList.add('sidebar-closed');
  }
}
</script>

<style scoped>
.custom-sidebar {
  background-color: #007bff;
  width: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 5rem;
  background-color: #007bff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000;
  overflow: hidden;
}

.image {
  margin-left: .5rem;
  margin-bottom: 1rem;
}

.brand-image {
  float: left;
  line-height: .8;
  margin-left: .5rem;
  margin-right: .5rem;
  margin-top: -3px;
  max-height: 50px;
  width: auto;
}
</style>
